import 'jquery'
import jquery from 'jquery'
const $: JQueryStatic = jquery
interface GalleryType {
    img: String,
    txt: String
}
export default class Gallery {
    start() {
        if ($('.gallery').length) {
            let gallery: GalleryType[] = [];

            $('.gallery').each(function () {
                gallery.push({
                    img: $(this).data('image'),
                    txt: $(this).find('.card-title').text()
                })
            })

            $('.gallery').each(function () {
                $(this).on('click', function () {
                    const target = $(this).attr('href')
                    $(target + ' .modal-content-image').html('<img src="' + $(this).data('image') + '">')
                    $(target + ' .modal-footer .text').html( $(this).find('.card-title').text())
                })
            })

            $('.gallery-modal .swiper-button .btn').each(function () {
                $(this).on('click', function () {
                    const target = $(this).data('target')
                    const HTMLtarget = $(this).attr('href')
                    if (gallery[target]) {
                        $(HTMLtarget + ' .modal-content-image').html('<img src="' + gallery[target].img + '">')
                    }
                })
            })

            console.log(gallery)
        }
    }
}
