export default class FeoSearchField extends HTMLElement {
    constructor() {
        super()
    }

    validateInput() {
        const inputEl: HTMLInputElement | null = this.querySelector('input[name="search"]')
        const isStandalone = this.hasAttribute('standalone')
        const baseUrl = this.dataset.baseUrl
        const searchToken = this.dataset.searchToken || 's'
        const searchValue = inputEl?.value?.trim()

        this.dispatchEvent(
            new CustomEvent('onValidate', {
                detail: { searchToken: searchValue || '' }
            })
        )

        if (isStandalone && baseUrl) {
            let url = `${baseUrl}`
            if (searchValue) {
                url = `${baseUrl}?${searchToken}=${searchValue}`
            }
            window.location.href = url
        }
    }

    init() {
        const inputEl = this.querySelector('input[name="search"]')
        const buttonEl = this.querySelector('button')

        inputEl?.addEventListener('keydown', evt => {
            if ((evt as KeyboardEvent).key === 'Enter') {
                this.validateInput()
            }
        })

        buttonEl?.addEventListener('click', this.validateInput.bind(this))
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        }, 250)
    }
}
