export default class FeoSelectFilterContent extends HTMLElement {
    constructor() {
        super()
    }

    resetFilters(groupName = '') {
        this.dispatchEvent(new CustomEvent('onResetFiltersGroup', { bubbles: true, detail: { groupName } }))
    }

    applyFilter() {
        this.dispatchEvent(new CustomEvent('onApplyFilter', { bubbles: true }))
    }

    init() {
        const btnReset: HTMLButtonElement | null = this.querySelector('button')
        const btnApply = this.querySelector('a.btn') as HTMLLinkElement
        btnApply?.addEventListener('click', (evt: MouseEvent) => {
            evt.preventDefault && evt.preventDefault()
            this.applyFilter()
        })
        btnReset?.addEventListener('click', () => {
            this.resetFilters(btnReset?.dataset.groupName)
        })
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        }, 250)
    }
}
