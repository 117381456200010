export default class FeoSelectCountry extends HTMLElement {
    private allCountriesIds: Array<string> = []
    private selectedCountryEl: HTMLElement | null | undefined = null
    private dropDownEl: HTMLElement | null | undefined = null

    constructor() {
        super()
    }

    renderSelectedCode(id: string) {
        if (this.allCountriesIds.includes(id)) {
            const selector = `.dropdown .option[data-id="${id}"]`
            const optionEl = this.querySelector(selector) as HTMLDivElement

            if (optionEl && this.selectedCountryEl) {
                this.selectedCountryEl.innerHTML = optionEl.innerHTML
            }
        }
    }

    dispatchSelectedCountryCode(id: string) {
        this.dispatchEvent(
            new CustomEvent('ON_SELECT_COUNTRY', {
                detail: { id }
            })
        )
    }

    onSelectCountry(id: string) {
        this.renderSelectedCode(id)
        this.dropDownEl?.classList.toggle('open', false)
        this.dispatchSelectedCountryCode(id)
    }

    init() {
        const optionEls = this.querySelectorAll('.option[data-id]')
        const selectButton = this.querySelector('.selected-item')
        this.dropDownEl = this.querySelector('.dropdown') as HTMLElement
        this.selectedCountryEl = selectButton?.querySelector('.selected-item .item')
        this.allCountriesIds = Array.from(optionEls).map(el => `${(el as HTMLElement).dataset.id}`)

        selectButton?.addEventListener('click', () => {
            this.dropDownEl?.classList.toggle('open')
        })

        optionEls.forEach(optEl => {
            ;(optEl as HTMLElement).addEventListener(
                'click',
                this.onSelectCountry.bind(this, `${(optEl as HTMLElement).dataset.id}`)
            )
        })
    }

    static get observedAttributes() {
        return ['data-current-country-id']
    }

    attributeChangedCallback() {
        this.renderSelectedCode(`${this.dataset.currentCountryId}`)
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        }, 250)
    }
}
