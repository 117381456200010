import Base from '@scripts/components/base'

export default class NavHeader extends Base {
    readonly navItem: NodeListOf<Element> = document.querySelectorAll('.menu-item-has-children')
    isMob = true
    mobWidth = 1023

    start() {
        console.log('Load nav')

        this.navDropDown()
        this.windowResize()
        this.menuBurger()
        this.isMobileDevice

        window.addEventListener('resize', () => {
            this.windowResize()
        })
    }

    menuBurger() {
        const menuBurger = document.querySelector('.burger-menu')

        menuBurger?.addEventListener('click', () => {
            const bodyTest = document.querySelector('body')
            const htmlTest = document.querySelector('html')
            const isMenuOpen = bodyTest?.classList.contains('open')

            if (isMenuOpen) {
                bodyTest?.classList.remove('open')
                bodyTest?.classList.remove('open-menu')
                htmlTest?.classList.remove('no-scroll')
            } else {
                bodyTest?.classList.add('open')
                bodyTest?.classList.add('open-menu')
                htmlTest?.classList.add('no-scroll')
            }
        })
    }

    getSubmenuHeight(clickedEl: any) {
        return clickedEl.querySelector('.sub-menu-content').offsetHeight
    }

    getSubmenuWidth(clickedEl: any) {
        return clickedEl.querySelector('.sub-menu-wrapper').offsetWidth
    }

    getMenuPosition(el: any) {
        const rect = el.getBoundingClientRect()

        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        }
    }

    windowResize() {
        this.isMobileDevice()
    }

    isMobileDevice() {
        this.isMob = window.innerWidth <= this.mobWidth
    }

    navDropDown() {
        this.navItem.forEach(el => {
            el.querySelector(':scope > a')?.addEventListener('mouseenter', () => {
                let parentMenuPosition = ''
                const subMenuParentHeight = this.getSubmenuHeight(el.querySelector('.sub-menu-wrapper'))
                const subMenuParentWidth = this.getSubmenuWidth(el)
                const clickedMenuPosition = this.getMenuPosition(el).left
                const windowWidth = window.innerWidth
                const parentHeight = !this.isMob ? 'max-height:' + subMenuParentHeight + 'px;' : ''
                const parentWidth = 'max-width:' + subMenuParentWidth + 'px;'

                if (!this.isMob) {
                    if (subMenuParentWidth + clickedMenuPosition > windowWidth) {
                        const elPosition = (windowWidth - parseInt(subMenuParentWidth)) / 2
    
                        parentMenuPosition = 'left: -50vw; margin-left: ' + elPosition / 4 + 'px;'
                    }
    
                    el?.querySelector('.sub-menu-parent')?.setAttribute(
                        'style',
                        parentHeight + parentMenuPosition + parentWidth
                    )
                }

                document.querySelectorAll('.menu-item-has-children').forEach(el => {
                    el.classList.remove('open')
                })
                el.classList.add('open')
            })

            el.querySelector('.sub-menu-parent')?.addEventListener('mouseleave', el2 => {
                this.windowResize()
                if (!this.isMob) this.closeSubMenu(el2)
            })

            el.querySelector('.nav-back')?.addEventListener('click', el3 => {
                if (this.isMob) el3.target?.parentNode.parentNode.parentNode.parentNode.classList.remove('open')
            })
        })
    }

    closeSubMenu(el) {
        const parentNodeLi = el.target?.parentNode
        const hasClass = parentNodeLi.classList.contains('open')
        const second = 500

        if (hasClass) {
            parentNodeLi.classList.remove('open')
            setTimeout(() => {
                console.log('test', el.target)
                el.target?.setAttribute('style', '')
            }, second)
        }
    }
}
