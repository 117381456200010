export default class FeoBreadcrumb extends HTMLElement {
    constructor() {
        super()
    }

    setTheme(theme: { [key: string]: string }) {
        const root: HTMLElement | null = document.querySelector(':root')
        for (const key in theme) {
            root?.style?.setProperty(`--${key}`, theme[key])
        }
    }

    init() {
        const themeStr = this.dataset.theme
        const jsonStr = themeStr?.replaceAll("'", '"')

        if (jsonStr) {
            try {
                const theme = JSON.parse(jsonStr)
                this.setTheme(theme)
            } catch (e: unknown) {
                console.error('breadcrumb: error while parsing theme string', e)
            }
        }
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        }, 100)
    }
}
