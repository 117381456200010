import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

export default class FeoCardsEventsSlider extends HTMLElement {
    private swiperInst: Swiper | null = null
    private swiperConf = {
        slidesPerView: 1.1,
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        breakpoints: {
            640: {
                slidesPerView: 2.1,
                modules: [Navigation, Pagination],
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            1024: {
                slidesPerView: 3,
                modules: [Navigation, Pagination],
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        }
    }

    constructor() {
        super()
    }

    updateSwiper() {
        this.swiperInst?.update()
    }

    init() {
        const containerEl = this.querySelector('.main-swiper-container') as HTMLDivElement
        if (containerEl) {
            this.swiperInst = new Swiper(containerEl, this.swiperConf)
            this.swiperInst.on('slideChange', thisSwiper => {
                if ('pagination' in thisSwiper) {
                    thisSwiper.pagination.render()
                    thisSwiper.pagination.update()
                }
            })

            if (this.querySelectorAll('.swiper-slide').length > 3) {
                const navButtons = this.querySelector('.swiper-button') as HTMLDivElement
                navButtons?.classList.remove('hidden')
            }

            window.addEventListener('resize', this.updateSwiper.bind(this))
        }
    }

    connectedCallback() {
        document.addEventListener('DOMContentLoaded', this.init.bind(this))
    }
}
