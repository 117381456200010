import FeoSelectFiltersTerms, { Term } from '@scripts/feo-select-filters-terms'

interface Criteria {
    label: string
    values: Array<string>
}
export default class FeoAlumniFilters extends HTMLElement {
    constructor() {
        super()
    }

    showHideCriteriaMobile() {
        const accordionFiltersEl = this.querySelector('.accordion-filters')

        document.querySelector('header.banner')?.classList.remove('hide-for-sticky')
        accordionFiltersEl?.classList.toggle('open')
    }

    getSelectedFilters(): Array<Criteria> {
        const result = [] as Array<Criteria>
        const allTermsEl: NodeListOf<HTMLInputElement> = this.querySelectorAll('input[type="checkbox"]')

        allTermsEl.forEach(checkEl => {
            if (checkEl.checked) {
                const token = `${checkEl.getAttribute('name')}`

                let found = false
                for(const item of result) {
                    if (item.label === token) {
                        item.values.push(checkEl.value)
                        // Remove duplicates
                        item.values = [...new Set(item.values)]
                        found = true
                        break
                    }
                }

                if (!found) {
                    result.push({
                        label: token,
                        values: [checkEl.value]
                    })
                }
            }
        })

        return result
    }

    applyFilter() {
        const filters = this.getSelectedFilters()
        const searchFieldEl = this.querySelector('feo-search-field input[name="search"]') as HTMLInputElement
        const params: Array<string> = []

        // Push search token
        if (searchFieldEl?.value?.trim()) {
            params.push(`search=${searchFieldEl?.value?.trim()}`)
        }

        // Push criteria
        if (filters.length) {
            for (const filter of filters) {
                params.push(`${filter.label}=${filter.values.join(',')}`)
            }
        }

        if (this.dataset.baseUrl) {
            const queryString = params.length ? `?${params.join('&')}` : ''
            window.location.href = `${this.dataset.baseUrl}${queryString}`
        }
    }

    // Load url without params
    resetFilter() {
        const allTermsEl = this.querySelectorAll('input[type="checkbox"]')

        allTermsEl.forEach(checkEl => {
            ;(checkEl as HTMLInputElement).checked = false
        })
        /*
        if (this.dataset.baseUrl && window.location.search) {
            window.location.href = this.dataset.baseUrl
        }
        */
        if (this.dataset.baseUrl) {
            window.location.href = this.dataset.baseUrl
        }
    }

    // Listen all input filter changes
    // And synchronize state between desktop & mobile
    listenForInputsChange() {
        const inputs: NodeListOf<HTMLInputElement> = this.querySelectorAll('input[type="checkbox"]')

        inputs.forEach(input => {
            input.addEventListener('change', (evt) => {
                try {
                    const theInput = evt.target as HTMLInputElement
                    const selector = `input[type="checkbox"][name="${theInput.name}"][value="${theInput.value}"]`
                    const relatedInputs: NodeListOf<HTMLInputElement> = this.querySelectorAll(selector)

                    relatedInputs.forEach(el => {
                        el.checked = theInput.checked
                    })
                } catch (e: unknown) {
                    console.log('error listening for input changes')
                }
                // this.updateTermsDisplay()
            })
        })
    }

    // Reset only grouped filters
    onResetFiltersGroup(groupName: string) {
        const selector = `input[type="checkbox"][name="${groupName}"]`
        const inputs: NodeListOf<HTMLInputElement> = this.querySelectorAll(selector)

        inputs.forEach(el => {
            el.checked = false
        })

        this.updateTermsDisplay()
    }

    // Update terms display
    updateTermsDisplay() {
        const filtersTermsContainer: FeoSelectFiltersTerms | null = this.querySelector('feo-select-filters-terms')
        const termsList: Array<Term> = []

        if (filtersTermsContainer) {
            const terms = this.getSelectedFilters()
            if (terms.length) {
                for (const term of terms) {
                    if (term.values) {
                        for (const id of term.values) {
                            const selector = `input[type="checkbox"][name="${term.label}"][value="${id}"] + label`
                            const labelEl: HTMLLabelElement | null = this.querySelector(selector)

                            labelEl && termsList.push({ id, label: labelEl.innerText, groupName: term.label })
                        }
                    }
                }
            }

            if (termsList.length) {
                filtersTermsContainer.updateTerms(termsList)
                console.log('termsList: ', termsList)
            }
        }
    }

    removeTerm(groupName: string, id: string) {
        const selector = `input[type="checkbox"][name="${groupName}"][value="${id}"]`
        const inputs: NodeListOf<HTMLInputElement> = this.querySelectorAll(selector)

        inputs.forEach(el => {
            el.checked = false
        })

        this.applyFilter()
    }

    init() {
        const triggerButtonOnMobile = this.querySelector('button.filters-button')
        const closeButtonOnMobile = this.querySelector('.accordion-filters-inner .heading button.icon-close')
        const applyButtonOnMobile = this.querySelector('.apply-buttons button.apply')
        const resetButtonOnMobile = this.querySelector('.apply-buttons button.reset')
        const searchFieldEl = this.querySelector('feo-search-field')

        triggerButtonOnMobile?.addEventListener('click', this.showHideCriteriaMobile.bind(this))
        closeButtonOnMobile?.addEventListener('click', this.showHideCriteriaMobile.bind(this))
        applyButtonOnMobile?.addEventListener('click', () => {
            this.applyFilter()
            this.showHideCriteriaMobile()
        })
        resetButtonOnMobile?.addEventListener('click', this.resetFilter.bind(this))
        searchFieldEl?.addEventListener('onValidate', this.applyFilter.bind(this))

        // onApplyFilter event from FeoSelectFilterContent
        this.addEventListener('onApplyFilter', () => {
            this.applyFilter()
            this.showHideCriteriaMobile()
        })

        this.addEventListener('onResetFiltersGroup', evt => {
            this.onResetFiltersGroup(`${(evt as CustomEvent).detail?.groupName}`)
        })
        this.addEventListener('onResetFilters', () => {
            this.resetFilter()
        })
        this.addEventListener('onRemoveTerm', (evt) => {
            const params = (evt as CustomEvent).detail
            this.removeTerm(`${params.groupName}`, `${params.id}`)
        })

        this.listenForInputsChange()
        this.updateTermsDisplay()
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        }, 250)
    }
}
