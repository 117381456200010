import Base from '@scripts/components/base'

export default class NavFooter extends Base {
    accordionContent= document.querySelectorAll('.footer_menu > .menu-item')

    start() {
        console.log('Footer Base')

        this.accordion()
    }

    accordion () {
        this.accordionContent.forEach((item,index) => {
            let header = item.querySelector('h3')

            header?.addEventListener('click', () => {
                let subMenu: HTMLElement | null = item?.querySelector('.sub-menu')

                // function to pass the index number of clicked header
                this.removeshowedContent(index)

                console.log('test s', item.classList.contains('show'))

                if (!item.classList.contains('show')) {
                    item.classList.add('show')
                    if (subMenu) subMenu.style.height = `${subMenu?.scrollHeight}px`
                } else {
                    console.log('test close')
                    item.classList.remove('show')
                    if (subMenu) subMenu.style.height = '0px'
                }
            })
        })
    }

    removeshowedContent(index: number){ 
        this.accordionContent.forEach((item2,index2)=>{ 
            if(index != index2){
                let descrip: HTMLElement | null = item2.querySelector('.sub-menu')

                item2.classList.remove('show')
                if (descrip) descrip.style.height = '0px'
            } 
        }) 
    }
}
