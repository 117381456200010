import CustomSwiper from './components/swiper'
import FeoSelectCountry from '@scripts/feo-select-country'

export default class FeoBlockAlumni extends HTMLElement {
    private API_BUNDLE_BASE_URL = 'https://get.geojs.io/v1/ip/geo.json'
    private DEFAULT_COUNTRY_CODE = 'fr'
    private selectCountryEl: FeoSelectCountry | null = null
    private allCountriesIds: Array<string> = []
    private allCountriesCode: Array<string> = []

    constructor() {
        super()
    }

    showLoading(state: boolean) {
        const loaderEl = this.querySelector('.loader')
        loaderEl && loaderEl.classList.toggle('show', state)
    }

    async getUserLocationCode(defaultCode: string) {
        const controller = new AbortController()
        const timeoutId = setTimeout(() => controller.abort(), 15000)
        let code = defaultCode

        try {
            const response = await fetch(this.API_BUNDLE_BASE_URL, {
                signal: controller.signal
            })
            clearTimeout(timeoutId)

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
            }
            const infoLocation = await response.json()
            infoLocation.country_code && (code = infoLocation.country_code.toLowerCase())
        } catch (e: unknown) {
            console.log('Error: ', e as string)
        }

        return code.toLowerCase()
    }

    async loadAlumni(countryId: string) {
        const dataset = this.dataset
        const formData = new FormData()

        // Build data
        formData.set('action', 'filter_by_country')
        formData.set('country', countryId)
        formData.set('security', `${dataset.ajaxNonce}`)

        try {
            const response = await fetch(`${dataset.ajaxUrl}`, {
                method: 'POST',
                body: formData
            })

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
            }

            // Handle html response
            return response.text()
        } catch (e: unknown) {
            throw new Error(`HTTP error! Status:`)
        }
    }

    renderSwiper(countryId: string, htmlString: string) {
        const alumnisSwiper = new CustomSwiper()
        const slidesContainer = this.querySelector('#filtered-country')

        slidesContainer && (slidesContainer.innerHTML = htmlString)
        alumnisSwiper.initializeAlumnisSwiper()
        this.selectCountryEl?.setAttribute('data-current-country-id', countryId)
    }

    async loadAndRender(countryCode: string | null, countryId?: string) {
        this.showLoading(true)
        try {
            const countryIdIndex = this.allCountriesCode.findIndex(code => countryCode?.toLowerCase() === code)
            const id = countryId || this.allCountriesIds[countryIdIndex]

            if (!id) {
                throw new Error('Country id not found')
            }

            const responseHtmlString = await this.loadAlumni(id)
            this.renderSwiper(id, `${responseHtmlString}`.slice(0, -1))
        } catch (e: unknown) {
            console.log('error: ', e)
        }
        this.showLoading(false)
    }

    async init() {
        this.selectCountryEl = this.querySelector('feo-select-country') as FeoSelectCountry
        const defaultCountryCode = this.dataset.defaultCountryCode ?? this.DEFAULT_COUNTRY_CODE

        if (this.selectCountryEl) {
            this.showLoading(true)
            this.allCountriesIds = `${this.dataset.allCountriesIds?.toLowerCase()}`.split(',')
            this.allCountriesCode = `${this.dataset.allCountriesCodes?.toLowerCase()}`.split(',')

            let countryCode = await this.getUserLocationCode(defaultCountryCode)
            const allCodes = `${this.dataset.allCountriesCodes}`

            // Check if country code is in all-countries_codes
            // Else take the first in line
            if (!allCodes.includes(countryCode)) {
                // If default code is among codes then we take it
                if (allCodes.includes(defaultCountryCode)) {
                    countryCode = defaultCountryCode
                } else {
                    const allCodesArr = allCodes.split(',')
                    for (const code of allCodesArr) {
                        if (code) {
                            countryCode = code
                            break
                        }
                    }
                }
            }

            countryCode && this.loadAndRender(countryCode)
            !countryCode && this.showLoading(false)

            // Listen for selected country
            this.selectCountryEl?.addEventListener('ON_SELECT_COUNTRY', payload => {
                try {
                    const detail = (payload as CustomEvent).detail
                    if (detail) {
                        this.loadAndRender(detail.code, detail.id)
                    }
                } catch (e: unknown) {
                    console.log('Fail!')
                }
            })
        }
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        }, 250)
    }
}
