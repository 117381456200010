import 'jquery'
import 'sticksy'
import jquery from 'jquery'
const $: JQueryStatic = jquery

export default class Sticky {
    start() {
        if (window.innerWidth > 1024) {
            if (document.querySelector('.js-sticky-widget')) {
                // @ts-ignore
                const stickyEl = new Sticksy('.js-sticky-widget', {
                    topSpacing: 80,
                })
            }

            console.log('[Sticky] bootsrap')
        }
    }
}
