export default class FeoGFormWrapper extends HTMLElement {
    constructor() {
        super()
    }

    checkLoading() {
        if (document.readyState === 'complete') {
            setTimeout(() => {
                this.scrollIntoView()
            }, 250)
        } else {
            setTimeout(() => {
                this.checkLoading()
            }, 100)
        }
    }

    init() {
        const selectorForm = `form[data-formid="${this.dataset.formId}"]`
        const selectorConfirmation = `#gform_confirmation_wrapper_${this.dataset.formId}`
        const formEl: HTMLFormElement | null = this.querySelector(selectorForm)
        const confirmationEl: HTMLDivElement | null = this.querySelector(selectorConfirmation)

        if (formEl || confirmationEl) {
            this.checkLoading()
        }
    }

    connectedCallback() {
        document.addEventListener('DOMContentLoaded', () => {
            this.init()
        })
    }
}
