export default class FeoHeaderEcole extends HTMLElement {
    constructor() {
        super()
    }

    init() {
        const blockFormationsEl: HTMLDivElement | null = document.querySelector('.wp-block-liste-formations')
        const listContainerEl = blockFormationsEl?.querySelector('.liste-formation-list')
        const formationsCount = listContainerEl?.querySelectorAll('a.card-formation')?.length

        if (formationsCount) {
            const btnGroup: HTMLDivElement | null = this.querySelector('.btn-group')
            const ctaEl: HTMLAnchorElement | undefined | null = btnGroup?.querySelector('.cta-inscription')
            const labelEl = ctaEl?.querySelector('span.label')

            // Add id ot formations block if not set
            blockFormationsEl?.setAttribute('id', 'formations')

            if (labelEl) {
                labelEl.innerHTML =
                    formationsCount === 1 ? `${ctaEl?.dataset.labelSingular}` : `${ctaEl?.dataset.labelPlural}`
                ;btnGroup?.classList.remove('hide-register')
            }
        }
    }

    connectedCallback() {
        document.addEventListener('DOMContentLoaded', this.init.bind(this))
    }
}
