import 'jquery'
import jquery from 'jquery'
const $: JQueryStatic = jquery

export default class DowloadCertificate {
    start() {
        $('.download-img-wrapper').click(function () {
            const downloadLink = $(this).find('.download-img')
            const imageUrl = $('.certificat-single img').attr('data-src')

            if (imageUrl) {
                downloadLink.attr('href', imageUrl)
                const downloadLinkElement = downloadLink[0] as HTMLAnchorElement
                downloadLinkElement.click()
            }
        })

        $('.download-img-wrapper-slide').on('click', function () {
            const downloadLink = $(this).find('.download-img-slide')
            const currentSlide = $('.certificat-swiper .swiper-slide-active img')
            const imageUrl = currentSlide.attr('data-src')

            if (imageUrl) {
                downloadLink.attr('href', imageUrl)
                const downloadLinkElement = downloadLink[0] as HTMLAnchorElement
                downloadLinkElement.click()
            }
        })
    }
}
