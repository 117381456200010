export interface Term {
    id: string
    label: string
    groupName?: string
}
export default class FeoSelectFiltersTerms extends HTMLElement {
    private currentTerms: Array<Term> = []

    constructor() {
        super()
    }

    getTermHTML(term: Term): HTMLDivElement {
        const div: HTMLDivElement = document.createElement('div')
        const span: HTMLSpanElement = document.createElement('span')
        const button: HTMLButtonElement = document.createElement('button')

        span.innerText = term.label
        button.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M12 4.5L4 12.5M12 12.5L4 4.5" stroke="black"/>
            </svg>
        `
        button.setAttribute('data-id', term.id)
        button.setAttribute('data-group-name', `${term.groupName}`)
        button.addEventListener('click', this.onRemoveTerm.bind(this))
        div.appendChild(span)
        div.appendChild(button)
        div.classList.add('term-wrapper')

        return div
    }

    getResetButton(): HTMLButtonElement {
        const button: HTMLButtonElement = document.createElement('button')

        button.innerText = this.dataset.textReset || 'Effacer les filtres'
        button.classList.add('clear-filters')
        button.addEventListener('click', this.onResetFilters.bind(this))

        return button
    }

    updateTerms(terms: Array<Term>) {
        this.currentTerms = terms
        this.innerHTML = ''

        for (const term of terms) {
            this.appendChild(this.getTermHTML(term))
        }

        terms.length && this.appendChild(this.getResetButton())
        this.classList.toggle('no-terms', !terms.length)
    }

    onRemoveTerm(evt: MouseEvent) {
        const btn = evt.target as HTMLButtonElement

        this.dispatchEvent(
            new CustomEvent('onRemoveTerm', {
                bubbles: true,
                detail: { groupName: btn.dataset.groupName, id: btn.dataset.id }
            })
        )
    }

    onResetFilters() {
        this.dispatchEvent(new CustomEvent('onResetFilters', { bubbles: true }))
    }
}
