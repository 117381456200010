export default class FeoPagination extends HTMLElement {
    constructor() {
        super()
    }

    openLink(toPageNum: number) {
        const basePath = this.dataset.basePath
        let queryString = this.dataset.queryString || ''

        if (basePath) {
            queryString = queryString ? `?${queryString}` : ''
            window.location.href =`${basePath}page/${toPageNum}/${queryString}`
        }
    }

    init() {
        const jThis = window.jQuery(this) as any
        const totalItems = this.dataset.totalItems ? parseInt(this.dataset.totalItems, 10) : 0
        // Current active page
        const pageNumber = this.dataset.pageNumber ? parseInt(this.dataset.pageNumber, 10) : 1
        // Number of data items per page.
        const pageSize = this.dataset.pageSize ? parseInt(this.dataset.pageSize, 10) : 3
        const dataSource = Array.from(Array(totalItems).keys()).map(i => i + 1)

        try {
            jThis.pagination({
                dataSource,
                pageSize,
                pageNumber,
                pageRange: 1,
                prevText: '&nbsp',
                nextText: '&nbsp',
                callback: (data: any, pagination: any) => {
                    console.log('data, pagination: ', data, pagination)
                },
                beforePaging: (toPageNumber: number) => {
                    if (pageNumber !== toPageNumber) {
                        this.openLink(toPageNumber)
                    }

                    return false
                }
            })
        } catch (e) {
            console.error('error: ', e)
        }
    }

    connectedCallback() {
        window.jQuery(document).ready(() => {
            this.init()
        })
    }
}
