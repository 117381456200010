import 'jquery'
import jquery from 'jquery'
const $: JQueryStatic = jquery

export default class ShareCertificate {
    start() {
        $('.share-img-slide').click(function (e) {
            e.preventDefault()
            const currentSlide = $('.certificat-swiper .swiper-slide-active img')
            let imageUrl = currentSlide.attr('data-src')
            if (currentSlide.attr('data-src-in')) {
                imageUrl = currentSlide.attr('data-src-in');
            }

            if (imageUrl) {
                window.open(
                    `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(imageUrl)}`,
                    'linkedin-share-dialog',
                    'width=600,height=400'
                )
            }
        })

        $('.share-img').click(function (e) {
            e.preventDefault()
            let imageUrl = $('.certificat-single img').attr('data-src')
            if ($('.certificat-single img').attr('data-src-in')) {
                imageUrl = $('.certificat-single img').attr('data-src-in');
            }

            if (imageUrl) {
                window.open(
                    `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(imageUrl)}`,
                    'linkedin-share-dialog',
                    'width=600,height=400'
                )
            }
        })
    }
}
