import 'jquery'
import jquery from 'jquery'
const $: JQueryStatic = jquery

export default class Tooltip {
    start() {
        const tooltip = $('.tooltip')
        if (tooltip.length > 0) {
            $('.tooltip').hover(
                function () {
                    const data = $(this).data('tooltip')
                    if (data) {
                        const tooltipContent = $('<div class="custom-tooltip">' + data + '</div>')
                        $(this).append(tooltipContent)
                    }
                },
                function () {
                    $('.custom-tooltip').remove()
                }
            )
        }
    }
}
