import 'jquery'
import jquery from 'jquery'
const $: JQueryStatic = jquery

export default class ShowHidden {
    start() {
        const $elementVisibles = $('.showHidden')

        $elementVisibles.each(function (){
            $(this).on('click', function () {
                const target = $(this).data('target')

                $(this).fadeOut(function () {
                    $(target).fadeIn()
                })
            })
        })

        console.log('[ShowHidden] bootstrap')
    }
}
