export default class FeoUtils {
    private lastScrollTop = 0
    private isScrollUp = false

    constructor() {
        this.lastScrollTop = document.documentElement.scrollTop
    }

    getStickyPlaceholder(stickyEl: HTMLElement) {
        const className = 'sticky-place-holder'
        let elementPlaceholder = stickyEl.nextElementSibling

        if (
            !elementPlaceholder ||
            !(elementPlaceholder && elementPlaceholder.classList.contains('sticky-place-holder'))
        ) {
            const placeholderEl = document.createElement('div')
            placeholderEl.classList.add(className)
            elementPlaceholder = stickyEl.insertAdjacentElement('afterend', placeholderEl)
        }

        return elementPlaceholder as HTMLElement
    }

    onStickiesScroll(stickies: NodeListOf<Element>) {
        // const MAX_TOP = 86
        stickies.forEach(element => {
            const stickyBody = (element as HTMLElement).dataset.stickyBody
            const stickToSelector = (element as HTMLElement).dataset.stickTo
            const stickToEl = document.querySelector(`${stickToSelector}`)
            /*
            const TOP_POS_Y =
                (stickToEl?.getBoundingClientRect().y || 0) + (stickToEl?.getBoundingClientRect().height || 0) ||
                MAX_TOP
            */
            const TOP_POS_Y = 0

            ;(element as HTMLElement).style.setProperty('--sticky-pos-y', `${TOP_POS_Y}px`)
            if (stickyBody) {
                const bodyEl: HTMLElement | null = document.querySelector(`*[data-sticky-id="${stickyBody}"]`)
                const bodyBounds = bodyEl?.getBoundingClientRect()
                const elementBounds = (element as HTMLElement).getBoundingClientRect()

                if (bodyBounds && bodyBounds.height > elementBounds.height) {
                    let isFixed = false
                    if (bodyBounds.y - elementBounds.height < TOP_POS_Y) {
                        isFixed = bodyBounds.y + bodyBounds.height >= TOP_POS_Y + elementBounds.height
                    }
                    element.classList.toggle('fixed', isFixed)
                    bodyEl?.classList.toggle('fixed', isFixed)

                    if (stickToSelector === 'header.banner') {
                        // If filters container mobile not open
                        if (!document.querySelector('.accordion-filters.open')) {
                            if (isFixed) {
                                stickToEl?.classList.add('hide-for-sticky')
                            } else {
                                stickToEl?.classList.remove('hide-for-sticky')
                            }
                        } else {
                            stickToEl?.classList.remove('hide-for-sticky')
                        }
                    }
                }
            }

            // Get/Create sibling
            const elementPlaceholder = this.getStickyPlaceholder(element as HTMLElement)
            if (elementPlaceholder) {
                elementPlaceholder.style.height = `${element.clientHeight}px`
            }
        })
    }

    initStickies() {
        const stickies = document.querySelectorAll('.sticky')

        if (stickies.length) {
            document.addEventListener('scroll', () => {
                this.isScrollUp = document.documentElement.scrollTop < this.lastScrollTop
                this.onStickiesScroll(stickies)
            })
        }
    }

    initBand() {
        const band = document.querySelector('.band') as HTMLElement
        const banner = document.querySelector('.banner') as HTMLElement
        const main = document.querySelector('.main') as HTMLElement
        const closeBand = document.querySelector('.band-close')

        function getCookie(name: string): string | null {
            const cookies = document.cookie.split(';').map(cookie => cookie.trim())
            const desiredCookie = cookies.find(cookie => cookie.startsWith(name + '='))

            if (desiredCookie) {
                return desiredCookie.substring(name.length + 1)
            }

            return null
        }

        function updateBandHeight() {
            if (!band) {
                return
            }
            const bandHeight = band.clientHeight

            const cookieValue = getCookie('myCookie')

            if (cookieValue !== null && cookieValue === 'true') {
                band.classList.add('hide')
            } else {
                banner.style.top = `${bandHeight}px`
                main.style.marginTop = `${bandHeight}px`
            }
        }

        // Check if the 'myCookie' cookie is set to true
        const isCookieSet = getCookie('myCookie') === 'true'

        if (band) {
            if (isCookieSet) {
                band.classList.add('hide')
            } else {
                updateBandHeight()
            }
        }

        if (closeBand !== null) {
            band?.classList.remove('hide')
            closeBand?.addEventListener('click', () => {
                band?.classList.add('hide')
                banner.style.top = ''
                main.style.marginTop = ''

                this.initStickyCertificatHeader()
            })
        }

        window.addEventListener('resize', () => {
            updateBandHeight()
            this.initStickyCertificatHeader()
        })
    }

    initStickyCertificatHeader() {
        const certificatHeader = document.querySelector('.certificat-header') as HTMLElement
        const certificatHeaderBottom = document.querySelector('.certificat-header-bottom') as HTMLElement
        const certificatHeaderTop = document.querySelector('.certificat-header-top') as HTMLElement
        const downloadButton = document.querySelector('.certificat-header-bottom .download') as HTMLElement
        const banner = document.querySelector('header.banner') as HTMLElement
        const band = document.querySelector('.certificat-header .band');
        /*
        const band = document.querySelector('.band') as HTMLElement
        */

        let navTotalHeight = 131
        let bandHeight = 0
        let bannerHeight = 0

        if (band) {
            bandHeight = band.clientHeight
        }
        if (banner) {
            bannerHeight = banner.clientHeight
        }

        const onResize = () => {
            if (band) {
                bandHeight = band.clientHeight
            }
            if (banner) {
                bannerHeight = banner.clientHeight
            }
            navTotalHeight = bandHeight + bannerHeight
            certificatHeader.style.setProperty('--top-position', `${navTotalHeight}px`)
        }


        if (certificatHeader) {
            window.addEventListener('scroll', () => {
                const bounding = certificatHeaderTop?.getBoundingClientRect()
                const bottomPosY = bounding?.y + bounding?.height

                if (bottomPosY) {
                    if (window.innerWidth > 879) {
                        certificatHeaderBottom?.classList.toggle('fix', bottomPosY < navTotalHeight)
                        downloadButton?.classList.remove('fix-button')

                        // If y pos of certificatHeader collide with header nav
                        // hide
                        if (banner && certificatHeaderBottom) {
                            const headerBound = banner.getBoundingClientRect()
                            const certificatHeaderBound = certificatHeaderBottom.getBoundingClientRect()

                            /* banner.classList.toggle(
                                'hide',
                                certificatHeaderBound.y < headerBound.y + headerBound.height
                            )*/
                        }
                    } else {
                        certificatHeaderBottom?.classList.remove('fix')
                        banner?.classList.remove('hide')
                        downloadButton?.classList.toggle('fix-button', bottomPosY < navTotalHeight)
                    }
                }
            })

            window.addEventListener('resize', () => {
                if (window.innerWidth <= 768) {
                    certificatHeaderBottom?.classList.remove('fix')
                }
            })

            certificatHeader.style.setProperty('--top-position', `${bandHeight + bannerHeight}px`)
            // onResize()
        }
    }

    start() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initStickies()
            this.initBand()
            this.initStickyCertificatHeader()
        })
    }
}
