export default class FeoSelectFilter extends HTMLElement {
    constructor() {
        super()
    }
    handleContentDisplay() {
        this.classList.toggle('open')
    }

    detectClickOut(evt: MouseEvent) {
        if (evt.target) {
            if (!this.contains(evt.target as Node)) {
                if (this.classList.contains('open')) {
                    this.classList.remove('open')
                }
            }
        }
    }

    init() {
        const buttonEl = this.querySelector('button')

        buttonEl?.addEventListener('click', this.handleContentDisplay.bind(this))
        document.addEventListener('click', this.detectClickOut.bind(this))
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        }, 250)
    }
}
