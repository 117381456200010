import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

export default class CustomSwiper {
    private principalSwiper: Swiper | null = null
    private secondarySwiper: Swiper | null = null
    private secondarySwiperMobile: Swiper | null = null
    private formationSwiper: Swiper | null = null
    private ecolesSwiper: Swiper | null = null
    private mediasSwiper: Swiper | null = null
    private categorySwiper: Swiper | null = null
    private ressourceSwiper: Swiper | null = null
    private alumnisSwiper: Swiper | null = null
    private objectifCompetenceSwiper: Swiper | null = null
    private certificatObtenuSwiper: Swiper | null = null
    private videosSwiper: Swiper | null = null
    private temoignageSwiper: Swiper | null = null
    private navCategoriesSwiper: Swiper | null = null

    start() {
        const swiperPrincipalOptions = {
            slidesPerView: 4,
            loop: true,
            breakpoints: {
                1280: {
                    spaceBetween: 60
                }
            }
        }

        const swiperSecondaireOptions = {
            slidesPerView: 2,
            spaceBetween: 50,
            modules: [Autoplay],
            loop: true,
            autoplay: {
                delay: 0,
            },
            speed: 2000,          //add
        }

        const swiperFormation = {
            slidesPerView: 1.1,
            spaceBetween: 30,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            breakpoints: {
                640: {
                    slidesPerView: 2.1,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                1024: {
                    slidesPerView: 3,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        }

        const swiperMedias = {
            slidesPerView: 2,
            spaceBetween: 20,
            centeredSlides: true,
            centeredSlidesBounds: true,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                640: {
                    slidesPerView: 3,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                1024: {
                    slidesPerView: 4,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        }

        const swiperCategory = {
            slidesPerView: 1.1,
            spaceBetween: 30,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            breakpoints: {
                640: {
                    slidesPerView: 3,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                1024: {
                    slidesPerView: 4,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        }

        const swiperRessource = {
            slidesPerView: 1.1,
            spaceBetween: 20,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            breakpoints: {
                640: {
                    slidesPerView: 2.1,
                    spaceBetween: 20,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        }

        const swiperPhotos = {
            slidesPerView: 1.1,
            spaceBetween: 20,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            breakpoints: {
                640: {
                    slidesPerView: 2.1,
                    spaceBetween: 20,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                992: {
                    slidesPerView: 2.3,
                    spaceBetween: 20,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
            }
        }

        const swiperAlumnis = {
            slidesPerView: 1.1,
            spaceBetween: 30,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            breakpoints: {
                640: {
                    slidesPerView: 2.1,
                    modules: [Navigation, Pagination]
                },
                1024: {
                    slidesPerView: 3.1,
                    modules: [Navigation, Pagination]
                },
                1280: {
                    slidesPerView: 4,
                    modules: [Navigation, Pagination]
                }
            }
        }
        const swiperObjectifCompetence = {
            slidesPerView: 1.1,
            spaceBetween: 30,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            breakpoints: {
                640: {
                    slidesPerView: 2.1,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                1024: {
                    slidesPerView: 3.1,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                1280: {
                    slidesPerView: 3,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        }
        const swiperVideos = {
            slidesPerView: 1.1,
            spaceBetween: 30,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            breakpoints: {
                640: {
                    slidesPerView: 2.1,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                1024: {
                    slidesPerView: 3.1,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        }

        const swiperEquipes = {
            slidesPerView: 1.1,
            spaceBetween: 30,
            modules: [Pagination],
            simulateTouch: false,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                559: {
                    slidesPerView: 2.2,
                    modules: [Pagination],
                },
                768: {
                    slidesPerView: 3.2,
                    modules: [Pagination],
                },
                1024: {
                    slidesPerView: 4,
                    modules: [Pagination],
                }
            }
        }

        const swiperCertificatObtenu = {
            slidesPerView: 'auto',
            spaceBetween: 30,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        }

        const swiperTemoignage = {
            slidesPerView: 1.1,
            spaceBetween: 20,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            breakpoints: {
                640: {
                    slidesPerView: 2.1,
                    spaceBetween: 30,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        }

        const swiperNavCategories = {
            slidesPerView: 'auto',
            spaceBetween: 25,
            freeMode: true,
            slidesOffsetBefore: 20,

            breakpoints: {
                640: {
                    slidesOffsetBefore: 0
                }
            }
        }

        const swiperPrincipalContainer = document.querySelector('.ecole-swiper') as HTMLElement
        const swiperSecondaryContainer = document.querySelector('.ecole-secondaire') as HTMLElement
        const swiperSecondaryContainerMobile = document.querySelector('.ecole-secondaire.mobile-only') as HTMLElement
        const swiperFormationContainer = document.querySelector('.formation-slide') as HTMLElement
        const swiperEcolesContainers = document.querySelectorAll('.ecoles-slide')
        const swiperMediasContainer = document.querySelector('.medias-slide') as HTMLElement
        const swiperCategoryContainer = document.querySelector('.category-slide') as HTMLElement
        const swiperRessourceContainers = document.querySelectorAll('.ressource-slide')
        const swiperAlumnisContainer = document.querySelector('.alumnis-list') as HTMLElement
        const swiperObjectifCompetenceContainer = document.querySelector('.objectif-competence-swiper') as HTMLElement
        const swiperCertificatObtenuContainer = document.querySelector('.certificat-swiper') as HTMLElement
        const swiperVideosContainers = document.querySelectorAll('.videos-slide')
        const swiperTemoignageContainer = document.querySelector('.temoignage-slide') as HTMLElement
        const swiperNavCategoriesContainer = document.querySelector('.nav-categories') as HTMLElement
        const swiperEquipesContainer = document.querySelectorAll('.equipes-wrapper')

        // Build Ecoles swiper instances
        let ecolesSwipers: Array<Swiper> = []

        const initializePrincipaleSwiper = () => {
            const isMobile = window.matchMedia('(max-width: 1024px)').matches

            if (swiperPrincipalContainer && isMobile) {
                if (this.principalSwiper) {
                    this.principalSwiper.destroy()
                    this.principalSwiper = null
                }

                this.principalSwiper = new Swiper(swiperPrincipalContainer, swiperPrincipalOptions)
                this.principalSwiper.on('slideChange', () => {
                    if ("pagination" in this.principalSwiper) {
                        this.principalSwiper.pagination.render();
                        this.principalSwiper.pagination.update();
                    }
                });
            } else if (this.principalSwiper) {
                this.principalSwiper.destroy()
                this.principalSwiper = null
            }

            if (this.principalSwiper) {
                this.principalSwiper.update()
            }
        }

        const initializeSecondarySwiper = () => {
            if (swiperSecondaryContainer) {
                if (this.secondarySwiper) {
                    this.secondarySwiper.destroy()
                    this.secondarySwiper = null
                }

                const show = swiperSecondaryContainer.dataset.show !== undefined ? parseInt(swiperSecondaryContainer.dataset.show) : 0
                swiperSecondaireOptions.slidesPerView = show
                this.secondarySwiper = new Swiper(swiperSecondaryContainer, swiperSecondaireOptions)

            } else if (this.secondarySwiper) {
                this.secondarySwiper.destroy()
                this.secondarySwiper = null
            }

            if (this.secondarySwiper) {
                this.secondarySwiper.update()
            }
        }

        const initializeSecondarySwiperMobile = () => {
            if (swiperSecondaryContainerMobile) {
                if (this.secondarySwiperMobile) {
                    this.secondarySwiperMobile.destroy()
                    this.secondarySwiperMobile = null
                }

                const show = swiperSecondaryContainerMobile.dataset.show !== undefined ? parseInt(swiperSecondaryContainerMobile.dataset.show) : 0
                swiperSecondaireOptions.slidesPerView = 3
                this.secondarySwiper = new Swiper(swiperSecondaryContainerMobile, swiperSecondaireOptions)

            } else if (this.secondarySwiperMobile) {
                this.secondarySwiperMobile.destroy()
                this.secondarySwiperMobile = null
            }

            if (this.secondarySwiperMobile) {
                this.secondarySwiperMobile.update()
            }
        }

        const initializeFormationSwiper = () => {
            if (swiperFormationContainer) {
                if (this.formationSwiper === null) {
                    this.formationSwiper = new Swiper(swiperFormationContainer, swiperFormation)
                    this.formationSwiper.on('slideChange', () => {
                        if ("pagination" in this.formationSwiper) {
                            this.formationSwiper.pagination.render();
                            this.formationSwiper.pagination.update();
                        }
                    });
                }
                if (this.formationSwiper) {
                    this.formationSwiper.update()
                }
            }
        }

        const initializeEcolesSwiper = () => {
            if (swiperEcolesContainers.length) {
                ecolesSwipers = Array.from(swiperEcolesContainers).map(slideContainer => {
                    const swiper = new Swiper(slideContainer as HTMLElement, swiperFormation)
                    swiper.on('slideChange', (swiper: Swiper) => {
                        if ('pagination' in swiper) {
                            swiper.pagination.render()
                            swiper.pagination.update()
                        }
                    })

                    return swiper
                })
            }
        }

        const updateEcolesSwipers = () => {
            ecolesSwipers.forEach(swiper => {
                swiper.update()
                if (swiper.pagination) {
                    swiper.pagination.render()
                    swiper.pagination.update()
                }
            })
        }

        const initializeMediasSwiper = () => {
            if (swiperMediasContainer) {
                if (this.mediasSwiper === null) {
                    this.mediasSwiper = new Swiper(swiperMediasContainer, swiperMedias)
                    this.mediasSwiper.on('slideChange', () => {
                        if ("pagination" in this.mediasSwiper) {
                            this.mediasSwiper.pagination.render();
                            this.mediasSwiper.pagination.update();
                        }
                    });
                }

                if (this.mediasSwiper) {
                    this.mediasSwiper.update()
                }
            }
        }

        const initializeCategorySwiper = () => {
            if (swiperCategoryContainer) {
                if (this.categorySwiper === null) {
                    this.categorySwiper = new Swiper(swiperCategoryContainer, swiperCategory)
                    this.categorySwiper.on('slideChange', () => {
                        if ("pagination" in this.categorySwiper) {
                            this.categorySwiper.pagination.render();
                            this.categorySwiper.pagination.update();
                        }
                    });
                }

                if (this.categorySwiper) {
                    this.categorySwiper.update()
                }
            }
        }

        const initializeRessourceSwiper = () => {
            const ressourceSwipers: Swiper[] = []
            for (let i = 0; i < swiperRessourceContainers.length; i++) {
                const swiperRessourceContainer = swiperRessourceContainers[i] as HTMLElement
                if (swiperRessourceContainer) {
                    const ressourceSwiper = new Swiper(swiperRessourceContainer, swiperRessource)

                    ressourceSwipers.push(ressourceSwiper)
                }
            }

            ressourceSwipers.forEach(swiper => {
                swiper.update()
                swiper.on('slideChange', function() {
                    swiper.pagination.render();
                    swiper.pagination.update();
                });
            })
        }

        const initializeAlumnisSwiper = () => {
            if (swiperAlumnisContainer) {
                if (this.alumnisSwiper === null) {
                    this.alumnisSwiper = new Swiper(swiperAlumnisContainer, swiperAlumnis)
                    this.alumnisSwiper.on('slideChange', () => {
                        if ("pagination" in this.alumnisSwiper) {
                            this.alumnisSwiper.pagination.render();
                            this.alumnisSwiper.pagination.update();
                        }
                    });
                }

                if (this.alumnisSwiper) {
                    this.alumnisSwiper.update()
                }
            }
        }

        const initializeObjectifCompetenceSwiper = () => {
            if (swiperObjectifCompetenceContainer) {
                if (this.objectifCompetenceSwiper === null) {
                    this.objectifCompetenceSwiper = new Swiper(
                        swiperObjectifCompetenceContainer,
                        swiperObjectifCompetence
                    )
                    this.objectifCompetenceSwiper.on('slideChange', () => {
                        if ("pagination" in this.objectifCompetenceSwiper) {
                            this.objectifCompetenceSwiper.pagination.render();
                            this.objectifCompetenceSwiper.pagination.update();
                        }
                    });
                }

                if (this.objectifCompetenceSwiper) {
                    this.objectifCompetenceSwiper.update()
                }
            }
        }

        const initializeVideoSwiper = () => {
            if (swiperVideosContainers) {
                const videoSwipers: Swiper[] = []
                for (let i = 0; i < swiperVideosContainers.length; i++) {
                    const swiperRessourceContainer = swiperVideosContainers[i] as HTMLElement
                    if (swiperRessourceContainer) {
                        let videoSwiper = null
                        if (swiperRessourceContainer.classList.contains('photos')) {
                            videoSwiper = new Swiper(swiperRessourceContainer, swiperPhotos)
                        } else {
                            videoSwiper = new Swiper(swiperRessourceContainer, swiperRessource)
                        }

                        videoSwipers.push(videoSwiper)
                    }
                }

                videoSwipers.forEach(swiper => {
                    swiper.update()
                    swiper.on('slideChange', function() {
                        swiper.pagination.render();
                        swiper.pagination.update();
                    });
                })
            }
        }

        const initializeCertificatObtenuSwiper = () => {
            if (swiperCertificatObtenuContainer) {
                if (this.certificatObtenuSwiper === null) {
                    this.certificatObtenuSwiper = new Swiper(swiperCertificatObtenuContainer, swiperCertificatObtenu)
                    this.certificatObtenuSwiper.on('slideChange', () => {
                        if ("pagination" in this.certificatObtenuSwiper) {
                            this.certificatObtenuSwiper.pagination.render();
                            this.certificatObtenuSwiper.pagination.update();
                        }
                    });
                }

                if (this.certificatObtenuSwiper) {
                    this.certificatObtenuSwiper.update()
                }
            }
        }

        const initializeTemoignageSwiper = () => {
            if (swiperTemoignageContainer) {
                if (this.temoignageSwiper === null) {
                    this.temoignageSwiper = new Swiper(swiperTemoignageContainer, swiperTemoignage)
                    this.temoignageSwiper.on('slideChange', () => {
                        if ("pagination" in this.temoignageSwiper) {
                            this.temoignageSwiper.pagination.render();
                            this.temoignageSwiper.pagination.update();
                        }
                    });
                }

                if (this.temoignageSwiper) {
                    this.temoignageSwiper.update()
                }
            }
        }

        const initializeNavCategoriesSwiper = () => {
            if (swiperNavCategoriesContainer) {
                if (!this.navCategoriesSwiper) {
                    this.temoignageSwiper = new Swiper(swiperNavCategoriesContainer, swiperNavCategories)
                    this.temoignageSwiper.on('slideChange', () => {
                        if ('pagination' in this.temoignageSwiper) {
                            this.temoignageSwiper.pagination.render();
                            this.temoignageSwiper.pagination.update();
                        }
                    });
                }

                this.navCategoriesSwiper?.update()
            }
        }

        const initEquipesSwipper = () => {
            if (swiperEquipesContainer) {
                const equipesSwipers: Swiper[] = []
                for (let i = 0; i < swiperEquipesContainer.length; i++) {
                    const swiperEquipeContainer = swiperEquipesContainer[i] as HTMLElement
                    if (swiperEquipeContainer) {
                        const equipesSwiper = new Swiper(swiperEquipeContainer, swiperEquipes)

                        equipesSwipers.push(equipesSwiper)
                    }
                }

                equipesSwipers.forEach(swiper => {
                    swiper.update()
                    swiper.on('slideChange', function() {
                        swiper.pagination.render();
                        swiper.pagination.update();
                    });
                })
            }
        }

        initializePrincipaleSwiper()
        initializeSecondarySwiper()
        initializeSecondarySwiperMobile()
        initializeFormationSwiper()
        initializeMediasSwiper()
        initializeCategorySwiper()
        initializeRessourceSwiper()
        initializeAlumnisSwiper()
        initializeVideoSwiper()
        initializeObjectifCompetenceSwiper()
        initializeCertificatObtenuSwiper()
        initializeTemoignageSwiper()
        initializeNavCategoriesSwiper()
        initializeEcolesSwiper()
        initEquipesSwipper()

        window.addEventListener('resize', () => {
            initializePrincipaleSwiper()
            initializeSecondarySwiper()
            initializeSecondarySwiperMobile()
            initializeFormationSwiper()
            initializeMediasSwiper()
            initializeCategorySwiper()
            initializeRessourceSwiper()
            initializeAlumnisSwiper()
            initializeVideoSwiper()
            initializeObjectifCompetenceSwiper()
            initializeCertificatObtenuSwiper()
            initializeTemoignageSwiper()
            initializeNavCategoriesSwiper()
            updateEcolesSwipers()
            initEquipesSwipper()
        })
    }

    initializeAlumnisSwiper() {
        if (this.alumnisSwiper) {
            this.alumnisSwiper.destroy()
            this.alumnisSwiper = null
        }

        const swiperAlumnisOptions = {
            slidesPerView: 1.1,
            spaceBetween: 30,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            breakpoints: {
                640: {
                    slidesPerView: 2.1,
                    modules: [Navigation, Pagination]
                },
                1024: {
                    slidesPerView: 3.1,
                    modules: [Navigation, Pagination]
                },
                1280: {
                    slidesPerView: 4,
                    modules: [Navigation, Pagination]
                }
            }
        }

        const swiperAlumnisContainer = document.querySelector('.alumnis-list') as HTMLElement;
        const swiperAlumnisPagination = swiperAlumnisContainer?.querySelector('.swiper-pagination') as HTMLElement

        if (swiperAlumnisContainer && swiperAlumnisPagination) {
            this.alumnisSwiper = new Swiper(swiperAlumnisContainer, swiperAlumnisOptions)
        }
    }
}
